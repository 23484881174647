/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable react/prop-types */
import React from 'react'
import { Form, Icon, Input, Button, Row, Col } from 'antd'

import firebase from '../../utils/firebaseConfig'

class NormalLoginForm extends React.Component {
  _login = (email, password) => {
    firebase
      .auth()
      .signInWithEmailAndPassword(email, password)
      // eslint-disable-next-line no-unused-vars
      .then((response) => {
        if (response) {
          this.props.navigate('/')
        }
      })
      .catch((error) => console.log(error))
  }

  handleSubmit = (e) => {
    e.preventDefault()
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this._login(values.username, values.password)
      }
    })
  }

  render() {
    const { getFieldDecorator } = this.props.form
    return (
      <Row className={'CommonRowStyle LoginForm'}>
        <Col span={8}></Col>
        <Col span={8}>
          <svg
            className="logo"
            version="1.1"
            id="Layer_1"
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            width="50px"
            height="50px"
            viewBox="0 0 90 90"
            enableBackground="new 0 0 90 90">
            <path
              d="M27.259,41.419c-3.31-1.516-7.057-2.855-10.585-4.286v22.314c0.166,13.924,12.03,25.404,24.215,26.455V75.639v-0.215
              l-0.003-12.223c0.034-4.617-1.526-9.807-4.054-13.416C34.375,46.07,30.557,42.904,27.259,41.419 M71.912,28.029l-0.004,1.54
              c-0.014,4.572-1.561,9.666-4.055,13.229c-2.458,3.712-6.275,6.88-9.574,8.366c-0.728,0.334-1.479,0.66-2.244,0.979
              c-1.027,0.494-2.077,0.916-3.143,1.268c-1.732,0.68-3.491,1.35-5.198,2.041v-0.967V44.218v-0.213l0.003-7.534v-3.36v-1.326
              c-0.03-4.621,1.527-9.808,4.055-13.419c2.459-3.712,6.277-6.879,9.573-8.365c3.308-1.515,7.057-2.858,10.587-4.287V28.029z
              M27.259,10c-3.31-1.515-7.057-2.858-10.585-4.287v8.125c-0.032,4.621,1.526,9.808,4.054,13.417c2.459,3.713,6.275,6.88,9.576,8.366
              c3.309,1.515,7.056,2.857,10.582,4.286v-8.124c0.034-4.621-1.526-9.808-4.054-13.419C34.375,14.653,30.557,11.486,27.259,10
              M61.325,57.141c3.308-1.518,7.057-2.859,10.587-4.285v6.592c-0.166,13.924-12.029,25.404-24.218,26.455V75.639l0.003,3.285
              c-0.03-4.619,1.527-9.807,4.055-13.42C54.211,61.793,58.029,58.627,61.325,57.141"
            />
          </svg>
          <h2>Kitchen Dashboard</h2>
          <Form onSubmit={this.handleSubmit} className="login-form">
            <Form.Item>
              {getFieldDecorator('username', {
                rules: [
                  { required: true, message: 'Please input your email!' },
                ],
              })(
                <Input
                  prefix={
                    <Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />
                  }
                  placeholder="Email"
                />,
              )}
            </Form.Item>
            <Form.Item>
              {getFieldDecorator('password', {
                rules: [
                  { required: true, message: 'Please input your Password!' },
                ],
              })(
                <Input
                  prefix={
                    <Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />
                  }
                  type="password"
                  placeholder="Password"
                />,
              )}
            </Form.Item>
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                className="login-form-button">
                Log in
              </Button>
            </Form.Item>
          </Form>
        </Col>
        <Col span={8}></Col>
      </Row>
    )
  }
}

const LoginForm = Form.create({ name: 'normal_login' })(NormalLoginForm)

export default LoginForm
