import { createStore, applyMiddleware, combineReducers } from 'redux'
import thunk from 'redux-thunk'

import reducers from './reducers'

function configureStore(initialState) {
  const store = createStore(
    combineReducers(reducers),
    initialState,
    applyMiddleware(thunk),
  )

  return store
}

export default configureStore
