import React from 'react'
import PropTypes from 'prop-types'

import { Select } from 'antd'
import { map, capitalize } from 'lodash'

const availableServiceOptions = ['lunch', 'dinner']

const renderOption = (option, index) => {
  return (
    <Select.Option value={option} key={index}>
      {capitalize(option)}
    </Select.Option>
  )
}

export default function ServiceSelector(props) {
  return (
    <Select
      onChange={(value) => props.selectService(value)}
      placeholder="Selected service"
      defaultValue={props.selectedService}
      style={{ width: props.width || 100 }}>
      {map(availableServiceOptions, renderOption)}
    </Select>
  )
}

ServiceSelector.propTypes = {
  selectService: PropTypes.func,
  selectedService: PropTypes.string,
  width: PropTypes.number,
}
