import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

const translations = {
  en: {
    translations: require('./translations/en.json'),
  },
}

i18n.use(initReactI18next).init({
  resources: translations,
  fallbackLng: 'en',
  debug: true,
  ns: ['translations'],
  defaultNS: 'translations',
  keySeparator: false,
  interpolation: {
    escapeValue: false,
    formatSeparator: ',',
  },
  react: {
    wait: true,
  },
})

export default i18n
