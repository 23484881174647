export const ACTION_TYPE = {
  SET_VERSION: 'SET_VERSION',
}

export function setVersion(appVersion) {
  return {
    type: ACTION_TYPE.SET_VERSION,
    appVersion,
  }
}
