import React from 'react'
import './App.css'

import { Router } from '@reach/router'

import PrivateRoute from '../PrivateRoute'

const NotFound = () => <p>Sorry, nothing here</p>

function App() {
  return (
    <div className="App">
      <Router>
        <NotFound default />
        <PrivateRoute path="/" />
      </Router>
    </div>
  )
}

export default App
