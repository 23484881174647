import React from 'react'
import { Provider } from 'react-redux'
import configureStore from '../redux/configureStore'

import App from './App'
import ApolloClient from 'apollo-boost'
import { ApolloProvider } from '@apollo/react-hooks'

const client = new ApolloClient({
  uri: process.env.REACT_APP_API_URL,
})

export default class ReduxApp extends React.Component {
  componentWillMount = () => {
    this._store = configureStore()
  }

  render() {
    return (
      <ApolloProvider client={client}>
        <Provider store={this._store}>
          <App />
        </Provider>
      </ApolloProvider>
    )
  }
}
