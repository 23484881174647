import React, { useState, useEffect, useRef } from 'react'

import { useQuery } from '@apollo/react-hooks'
import { gql } from 'apollo-boost'
import moment from 'moment'
import createPersistedState from '@plq/use-persisted-state'

import { DatePicker, Table, Row, Col, Button } from 'antd'
import HubSelector from '../common/HubSelector'
import WrappedNormalLoginForm from './CookedSkuForm'
import ServiceSelector from '../common/ServiceSelector'

import transformCookingSkus from '../../utils/transformCookingSkus'
import transformCookingTimes from '../../utils/transformCookingTimes'
import firebase from '../../utils/firebaseConfig'

import './main.css'

const NAV_BAR_HEIGHT = 54
const TABLE_HEADER = 55
const BOTTOM_PADDING = 25
const TIME_COLUMN_WIDTH = 100
const FIXED_COLUMNS_WIDTH = 420

// Create pesisted state
const [usePersistedState, clear] = createPersistedState('orbit')

// https://usehooks.com/usePrevious/
// Hook to use previous state
function usePrevious(value) {
  // The ref object is a generic container whose current property is mutable ...
  // ... and can hold any value, similar to an instance property on a class
  const ref = useRef()

  // Store current value in ref
  useEffect(() => {
    ref.current = value
  }, [value]) // Only re-run if value changes

  // Return previous value (happens before update in useEffect above)
  return ref.current
}

const db = firebase.firestore()
const addCookedSkus = (
  values,
  selectedTime,
  selectedHub,
  selectedServiceTiming,
  updateCookedSkus,
  setCookingSkusStatus,
) => {
  if (values) {
    // Add cookedSkus to the firebase
    setCookingSkusStatus(true)
    db.collection('cookedSkus')
      .add({
        cookedAt: moment().format(),
        cookedSkus: values,
        hub: selectedHub,
        servingDate: moment(selectedTime).format('YYYY-MM-DD'),
        serviceTiming: selectedServiceTiming,
      })
      .then(() => {
        getCookedSkus(selectedHub, selectedTime, selectedServiceTiming).then(
          (response) => {
            updateCookedSkus(response)
            setCookingSkusStatus(false)
          },
        )
      })
  }
}

const getCookedSkus = async (
  selectedHub,
  selectedTime,
  selectedServiceTiming,
) => {
  const cookedSkusCollection = await firebase
    .firestore()
    .collection('cookedSkus')
    .where('servingDate', '==', moment(selectedTime).format('YYYY-MM-DD'))
    .where('hub', '==', selectedHub)
    .where('serviceTiming', '==', selectedServiceTiming)
    .get()

  const cookedSkus = []
  cookedSkusCollection.forEach((doc) => {
    const cookedSku = doc.data()
    cookedSkus.push(cookedSku)
  })

  return cookedSkus
}

const logout = () => {
  if (firebase.auth().signOut()) {
    window.location.reload()
    clear()
  }
}

const DEFAULT_TIME_VALUE = moment(moment(), 'YYYY-MM-DD')

const COOKING_MANIFEST = gql`
  query($date: ISO8601DateTime!, $hub: String!, $serviceTiming: String!) {
    cookingManifest(date: $date, hub: $hub, serviceTiming: $serviceTiming) {
      manifestTime
      cookingTimes
      menuSkus {
        name
        mealType
      }
      cookingSkus {
        name
        quantity
        cookTime
      }
    }
  }
`

const transformedCookingSkus = (data, cookedSkus) => {
  if (!data) return null

  return transformCookingSkus(
    data.cookingManifest.cookingSkus,
    data.cookingManifest.cookingTimes,
    data.cookingManifest.menuSkus,
    cookedSkus,
  )
}

const transformedCookingTimes = (data) => {
  if (!data) return null

  return transformCookingTimes(data.cookingManifest.cookingTimes)
}

const columns = [
  {
    title: 'Meals',
    width: 100,
    dataIndex: 'name',
    key: 'name',
    fixed: 'left',
    className: 'Meals',
  },
  {
    title: 'Committed',
    width: 100,
    dataIndex: 'committed',
    className: 'Committed',
    key: 'skuCount',
    fixed: 'left',
  },
  {
    title: 'Cooked',
    width: 100,
    dataIndex: 'cooked',
    key: 'cooked',
    fixed: 'left',
  },
]

const getColumns = (data) => {
  if (!data) return columns

  return columns.concat(transformedCookingTimes(data))
}

function Orbit() {
  const [selectedHub, getCookingSkusForHub] = usePersistedState(
    'selectedHub',
    null,
  )
  const [selectedTime, getCookingSkus] = usePersistedState(
    'selectedTime',
    DEFAULT_TIME_VALUE,
  )
  const [selectedServiceTiming, selectServiceTiming] = usePersistedState(
    'selectedServiceTiming',
    'lunch',
  )
  const [formVisible, toggleSkuForm] = useState(false)
  const [cookedSkus, updateCookedSkus] = useState({})
  const [cookingSkusChanged, updateCookingSkuChanges] = useState(false)
  const [loadingCookingSkus, setCookingSkusStatus] = useState(false)

  // eslint-disable-next-line no-unused-vars
  const { loading, _, data, startPolling, stopPolling } = useQuery(
    COOKING_MANIFEST,
    {
      fetchPolicy: 'network-only',
      variables: {
        date: moment(selectedTime).format(),
        hub: selectedHub ? selectedHub : null,
        serviceTiming: selectedServiceTiming,
      },
    },
  )

  useEffect(() => {
    stopPolling()
    if (selectedHub && selectedTime) {
      setCookingSkusStatus(true)
      startPolling(15000)
      getCookedSkus(selectedHub, selectedTime, selectedServiceTiming).then(
        (response) => {
          setCookingSkusStatus(false)
          updateCookedSkus(response)
        },
      )
    }
  }, [
    selectedHub,
    selectedTime,
    stopPolling,
    startPolling,
    selectedServiceTiming,
  ])

  // If selected hub has any updates, notify user
  const previouslySelectedHub = usePrevious(selectedHub)
  const lastRetrievedData = usePrevious(data)
  useEffect(() => {
    if (
      previouslySelectedHub === selectedHub &&
      lastRetrievedData &&
      lastRetrievedData !== data
    ) {
      updateCookingSkuChanges(true)
      document.getElementById('audio').play()
    }
  }, [data, selectedHub, previouslySelectedHub, lastRetrievedData])

  return (
    <div
      className={
        'CookingSkus ' + (cookingSkusChanged ? 'CookingSkusChanged' : null)
      }>
      <Row className={'CommonRowStyle'}>
        <Col span={4} className="Legends">
          <h5>Legend:</h5>
          <div className="Legend">
            <span>Off hour timings: </span>
            <span className="OffHour" />
          </div>
        </Col>
        <Col span={4}>
          <Button
            type="primary"
            onClick={() => toggleSkuForm(true)}
            disabled={!selectedHub}>
            Record cooked meals
          </Button>
        </Col>
        <Col span={4}>
          <HubSelector
            className={'Header'}
            selectedHub={selectedHub}
            selectHub={(selectedHub) => getCookingSkusForHub(selectedHub)}
          />
        </Col>
        <Col span={4}>
          <DatePicker
            allowClear={false}
            onChange={(_date, dateString) => getCookingSkus(dateString)}
            defaultValue={moment(moment(selectedTime), 'YYYY-MM-DD')}
          />
        </Col>
        <Col span={4}>
          <ServiceSelector
            className={'Header'}
            selectedService={selectedServiceTiming}
            selectService={(selectedServiceTiming) =>
              selectServiceTiming(selectedServiceTiming)
            }
          />
        </Col>
        <Col span={4} className="Actions">
          <Button type="primary" onClick={() => logout()}>
            Logout
          </Button>
        </Col>
      </Row>
      <Row className={'CommonRowStyle'}>
        {!!cookingSkusChanged && (
          <div className="DismissMe">
            <h1>There are incoming orders!</h1>
            <Button
              type="primary"
              onClick={() => updateCookingSkuChanges(false)}
              disabled={!selectedHub}>
              Dismiss
            </Button>
          </div>
        )}
        <Col span={24}>
          <Table
            loading={loading || loadingCookingSkus}
            dataSource={transformedCookingSkus(data, cookedSkus)}
            columns={getColumns(data)}
            scroll={{
              x:
                (getColumns(data).length - 3) * TIME_COLUMN_WIDTH +
                FIXED_COLUMNS_WIDTH,
              y:
                window.innerHeight -
                (NAV_BAR_HEIGHT + TABLE_HEADER + BOTTOM_PADDING),
            }}
            pagination={false}
            bordered={true}
          />
        </Col>
      </Row>
      <WrappedNormalLoginForm
        submitForm={(values) =>
          addCookedSkus(
            values,
            selectedTime,
            selectedHub,
            selectedServiceTiming,
            updateCookedSkus,
            setCookingSkusStatus,
          )
        }
        cookingSkus={transformedCookingSkus(data)}
        cookedSkus={cookedSkus}
        title="Record cooked meals"
        visible={formVisible}
        onOk={() => toggleSkuForm(false)}
        onCancel={() => toggleSkuForm(false)}
      />
    </div>
  )
}

Orbit.displayName = 'Orbit'

Orbit.propTypes = {}

document.title = Orbit.displayName

export default Orbit
