import React from 'react'
import PropTypes from 'prop-types'

import { useQuery } from '@apollo/react-hooks'
import { gql } from 'apollo-boost'

import { Select } from 'antd'
import { map } from 'lodash'

import transformHubs from '../../utils/transformHubs'

const HUBS_QUERY = gql`
  {
    hubs {
      name
      shortCode
    }
  }
`

const renderOption = (option, index) => {
  return (
    <Select.Option value={option.value} key={index}>
      {option.label}
    </Select.Option>
  )
}

export default function HubSelector(props) {
  const { loading, error, data } = useQuery(HUBS_QUERY)

  if (error) {
    return (
      <div>
        <p>{error}</p>
      </div>
    )
  }

  return (
    <Select
      loading={loading}
      onChange={(value) => props.selectHub(value)}
      placeholder="Selected hub"
      defaultValue={props.selectedHub}
      style={{ width: props.width || 150 }}>
      {map(transformHubs(data ? data.hubs : []), renderOption)}
    </Select>
  )
}

HubSelector.propTypes = {
  loading: PropTypes.bool,
  error: PropTypes.object,
  selectHub: PropTypes.func,
  selectedHub: PropTypes.string,
  width: PropTypes.number,
}
