/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable react/prop-types */
import React from 'react'
import { Form, InputNumber, Modal } from 'antd'
import { map, each } from 'lodash'

class CookedSkuForm extends React.Component {
  handleSubmit = (e) => {
    e.preventDefault()
    const { form } = this.props
    this.props.form.validateFields((err, values) => {
      if (err) {
        return
      }
      this.props.submitForm(values)
      this.props.onOk()
      form.resetFields()
    })
  }

  transformedCookedSkus = (cookedSkuList) => {
    if (!cookedSkuList) return {}
    let flattenendCookedSkus = {}

    each(cookedSkuList, (cookedList) => {
      Object.keys(cookedList.cookedSkus).forEach((skuName) => {
        if (flattenendCookedSkus[skuName]) {
          flattenendCookedSkus[skuName] += parseInt(
            cookedList.cookedSkus[skuName],
          )
        } else {
          flattenendCookedSkus[skuName] = parseInt(
            cookedList.cookedSkus[skuName],
          )
        }
      })
    })

    return flattenendCookedSkus
  }

  cookingDelta = (sku, cookedSkus) => {
    const cooked = cookedSkus[sku.name] || 0

    if (cooked > sku.committed) {
      return 0
    } else {
      return sku.committed - cooked
    }
  }

  render() {
    const { getFieldDecorator } = this.props.form
    const cookedSkuList = this.transformedCookedSkus(this.props.cookedSkus)

    return (
      <Modal
        title={this.props.title}
        visible={this.props.visible}
        onOk={this.handleSubmit}
        onCancel={this.props.onCancel}
        okText={'Submit'}>
        {this.props.visible ? (
          <Form className="cooked-sku-form" onSubmit={this.handleSubmit}>
            {map(this.props.cookingSkus, (sku, index) => {
              let cookedDelta = this.cookingDelta(sku, cookedSkuList)
              return (
                <Form.Item key={index}>
                  {getFieldDecorator(sku.name, {
                    initialValue: cookedDelta,
                    rules: [
                      { required: true, message: 'Please input a quantity!' },
                    ],
                  })(
                    <div className="FormEntry" key={index}>
                      <div>{sku.name}</div>
                      <InputNumber defaultValue={cookedDelta} />
                    </div>,
                  )}
                </Form.Item>
              )
            })}
          </Form>
        ) : null}
      </Modal>
    )
  }
}

const WrappedCookedSkuForm = Form.create({ name: 'cooked_sku' })(CookedSkuForm)

export default WrappedCookedSkuForm
