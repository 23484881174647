import React from 'react'
import firebase from '../../utils/firebaseConfig'
import Orbit from '../Orbit'
import Login from '../Login'

export default class PrivateRoute extends React.Component {
  constructor(props) {
    super(props)
    this._initializeUser()
  }

  state = {
    currentUser: null,
    loading: true,
  }

  _initializeUser = () => {
    const _this = this
    firebase.auth().onAuthStateChanged(function(user) {
      if (user) {
        _this.setState({
          currentUser: user,
          loading: false,
        })
      } else {
        _this.setState({
          loading: false,
        })
      }
    })
  }

  render() {
    if (this.state.loading) return <p>loading...</p>

    if (this.state.currentUser) return <Orbit />

    return <Login />
  }
}
