import _ from 'lodash'
import moment from 'moment'

const transformCookingSkus = (
  cookingSkus,
  cookingTimes,
  menuSkus,
  cookedSkus,
) => {
  const uncommittedMenuSkus = _.reject(menuSkus, (menuSku) =>
    _.find(cookingSkus, { name: menuSku.name }),
  )

  const uncommittedCookingSkus = _.map(uncommittedMenuSkus, (menuSku) => {
    return {
      name: menuSku.name,
      quantity: 0,
      cookTime: null,
      __typename: 'CookingSku',
    }
  })

  const allCookingSkus = _.concat(cookingSkus, uncommittedCookingSkus)

  let skuCategoryGroups = _.groupBy(allCookingSkus, function(cookingSku) {
    let skuStock = _.find(menuSkus, { name: cookingSku.name })
    return skuStock ? skuStock.mealType : 'offmenu'
  })

  let result = []
  const categoryOrder = ['main', 'side', 'dessert', 'offmenu']
  _.each(categoryOrder, function(category) {
    let values = skuCategoryGroups[category]
    let skuGroups = _.groupBy(values, function(sku) {
      return sku.name
    })

    let tempResult = []

    _.each(_.keys(skuGroups), function(skuName, index) {
      let skuDetails = skuGroups[skuName]
      let baseObject = {
        name: skuName,
        cookingTimes: values.length,
        quantity: 0,
        cooked: 0,
        key: `${category}-${index}`,
        className: 'Meals',
      }

      const totalQuantity = _.reduce(
        skuDetails,
        function(transformedSku, skuItem) {
          let time = moment(skuItem.cookTime)

          // Writes into the time specific quantity collector
          baseObject[time.format('HH:mm')] =
            baseObject[time.format('HH:mm')] || 0
          baseObject[time.format('HH:mm')] += parseInt(skuItem.quantity)

          // eslint-disable-next-line no-sequences
          return (transformedSku += skuItem.quantity), transformedSku
        },
        0,
      )

      baseObject.committed = totalQuantity

      _.each(cookedSkus, function(cookedRecord) {
        if (cookedRecord.cookedSkus[skuName]) {
          baseObject['cooked'] += parseInt(cookedRecord.cookedSkus[skuName])
        }
      })

      baseObject['depleted'] = baseObject['cooked']

      // Fills empty time records with 0's and shows cooked progress for committed timings
      _.each(cookingTimes, (rawTime) => {
        let time = moment(rawTime)
        baseObject[time.format('HH:mm')] = baseObject[time.format('HH:mm')] || 0
        const requiredAmount = baseObject[time.format('HH:mm')]

        if (requiredAmount > 0) {
          if (baseObject['depleted'] >= requiredAmount) {
            baseObject['depleted'] = baseObject['depleted'] - requiredAmount
            if (baseObject['depleted'] >= 0) {
              baseObject[
                time.format('HH:mm')
              ] = `${requiredAmount} / ${requiredAmount}`
              baseObject['className'] = 'Fulfiled'
            } else {
              baseObject[time.format('HH:mm')] = `❌ ${
                baseObject['depleted']
              } / ${requiredAmount}`
            }
          } else {
            baseObject[time.format('HH:mm')] = `❌ ${baseObject['depleted'] ||
              0} / ${requiredAmount}`
            baseObject['depleted'] = 0
          }
        }
      })

      delete baseObject.depleted
      baseObject['cooked'] = baseObject['cooked'] || 0

      tempResult.push(baseObject)
    })
    result = result.concat(_.orderBy(tempResult, ['name'], ['asc']))
  })

  return result
}

export default transformCookingSkus
