import { ACTION_TYPE } from './appVersionActions'

const initialState = {
  appVersion: '',
}

export default function appVersionReducer(state = initialState, action) {
  switch (action.type) {
    case ACTION_TYPE.SET_VERSION: {
      const { appVersion } = action
      return { appVersion }
    }
    default:
      return state
  }
}
