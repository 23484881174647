import { map } from 'lodash'
import moment from 'moment'

const transformCookingTimes = (cookingTimes) => {
  const result = map(cookingTimes, (cookingTime, index) => {
    let time = moment(cookingTime)
    let offHourTime = moment(cookingTime)
      .hours(10)
      .minutes(15)
      .seconds(0)

    const className = time.isBefore(offHourTime) ? 'OffHour' : null

    return {
      title: time.format('HH:mm'),
      dataIndex: time.format('HH:mm'),
      className: className,
      key: index,
    }
  })

  return result
}

export default transformCookingTimes
